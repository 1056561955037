import * as React from "react";
import { useEffect } from "react";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import CartItem from "./CartItem";
import useInterval from "../../hooks/UseInterval";
import { CartItemData } from "../../../models/cartData";
import { getCart } from "../../../redux/cart/actions";
import {
  CHECK_CART_EACH_MINUTE,
  EXPIRATION_DURATION,
} from "../../../redux/cart/reducers";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Utils } from "../../../services/utils";
import { useTranslation } from "react-i18next";

interface CartListProps {
  checkout?: boolean;
}

const CartList: React.FC<CartListProps> = ({ checkout = false }) => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const cart = useSelector((state: AppState) => state.cart);
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);

  useInterval(() => {
    if (cart.cart?.cart_items) {
      checkDurationOfCartItems(cart.cart.cart_items);
    }
  }, CHECK_CART_EACH_MINUTE);

  const checkDurationOfCartItems = (cartItems: CartItemData[]) => {
    cartItems.forEach((cartItem) => {
      if (Date.now() > new Date(cartItem.created_on).getTime() + EXPIRATION_DURATION) {
        dispatch(getCart());
      }
    });
  };

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cart.cart.cart_items, cart.cartInitialLoaded, dispatch, isUserLoggedIn]);

  /**
   * Check if any of the cart items have a discount
   * @returns {boolean} True if any of the cart items have a discount
   * Directly compare if it's a number
   * Parse if it's a string
   * Otherwise return false
   */
  const hasSomeDiscount = cart.cart?.cart_items.some((item) => {
    if (typeof item?.discount === "number") {
      return item.discount > 0;
    }
    if (typeof item?.discount === "string") {
      return parseFloat(item.discount) > 0;
    }
    return false;
  });

  const {
    total_discount: totalDiscount = 0,
    vat_totals: amountsPerTaxRate = [],
    net_total: nettoPrice = 0,
    gross_total: total = 0,
  } = cart.cart || {};

  const getColumnCount = (): number => {
    const minColumn = checkout ? 5 : 3;

    if (xs) {
      return minColumn;
    }

    if (sm) {
      return 1 + minColumn;
    }

    return minColumn + 4;
  };

  const columnCount = getColumnCount();
  const summaryOffset = columnCount - 3 - 2;

  return cart.cart?.cart_items.length > 0 ? (
    <TableContainer className="shopping-cart-tablebody">
      <Table aria-label="cart items">
        <TableHead>
          <TableRow>
            <Hidden smDown>
              <TableCell component="th" scope="row">
                {t("course.courseID", { ns: "common" })}
              </TableCell>
            </Hidden>
            <TableCell align="left">
              {t("course.courseTitle", { ns: "common" })}
            </TableCell>
            <Hidden xsDown>
              <TableCell align="left">{t("cart.date", { ns: "booking" })}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left"> {t("cart.net", { ns: "booking" })}</TableCell>
            </Hidden>
            {hasSomeDiscount ? (
              <TableCell align="right" id="table-cell-cart-list-discount">
                {t("cart.discount", { ns: "booking" })}
              </TableCell>
            ) : null}
            {checkout ? (
              <>
                <TableCell align="right"> {t("cart.tax", { ns: "booking" })}</TableCell>
                <TableCell align="right">
                  {" "}
                  {t("cart.taxAmount", { ns: "booking" })}
                </TableCell>
              </>
            ) : null}
            <TableCell align="right">{t("cart.gross", { ns: "booking" })}</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {cart.cart?.cart_items.map((cartItem) => {
            return (
              <CartItem
                event={cartItem}
                key={cartItem?.id}
                checkout={checkout}
                hasSomeDiscount={hasSomeDiscount}
              />
            );
          })}

          {checkout ? (
            <>
              <TableRow>
                {summaryOffset > 0 && (
                  <TableCell className={classes.root} colSpan={summaryOffset} />
                )}
                <TableCell colSpan={3} align="right">
                  {t("cart.netTotal", { ns: "booking" })}
                  <Hidden smUp>
                    <br />
                    <span style={{ fontSize: "0.75rem" }} />
                  </Hidden>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <b>{Utils.formatPrice(nettoPrice, true, false)}</b>
                </TableCell>
              </TableRow>

              {hasSomeDiscount ? (
                <TableRow id="table-row-cart-list-discount">
                  {summaryOffset > 0 && (
                    <TableCell className={classes.root} colSpan={summaryOffset} />
                  )}
                  <TableCell colSpan={3} align="right">
                    {t("cart.discount", { ns: "booking" })}: {}
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <b>-{Utils.formatPrice(totalDiscount, true, false)}</b>
                  </TableCell>
                </TableRow>
              ) : null}

              {amountsPerTaxRate.map((perTaxRate) => {
                return (
                  <TableRow key={perTaxRate.value_added_taxrate}>
                    {summaryOffset > 0 && (
                      <TableCell className={classes.root} colSpan={summaryOffset} />
                    )}
                    <TableCell colSpan={3} align="right">
                      {t("cart.totalWithTax", { ns: "booking" })}{" "}
                      {Utils.formatTax(perTaxRate.value_added_taxrate)} %:
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {Utils.formatPrice(perTaxRate.total, true, false)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          ) : null}

          <TableRow>
            {summaryOffset > 0 && (
              <TableCell className={classes.root} colSpan={summaryOffset} />
            )}
            <TableCell colSpan={xs && !checkout ? 2 : 3} align="right">
              {checkout
                ? t("cart.priceAmountDue", { ns: "booking" })
                : t("cart.priceTotal", { ns: "booking" })}
            </TableCell>
            <TableCell colSpan={2} align="right">
              <u>
                <b>{Utils.formatPrice(total, true, false)}</b>
              </u>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer className="shopping-cart-tablebody">
      <Table aria-label="cart items">
        <TableBody>
          <TableRow>
            <TableCell className="shopping-cart-emptyinfocell">
              {t("cart.emptyCartNote", { ns: "booking" })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderBottom: "none",
    },
  })
);

export default CartList;
